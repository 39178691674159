import React from "react";
import { useOverrides } from "@quarkly/components";
import { Button } from "@quarkly/widgets";
const defaultProps = {
	"margin": "0px 0 0px 0px",
	"padding": "12px 28px 12px 28px",
	"background": "rgba(0, 119, 204, 0)",
	"color": "--darkL1",
	"font": "normal 400 17px/1.5 --fontFamily-sans",
	"border-width": "1px",
	"border-radius": "5px",
	"href": "/contact-us",
	"type": "link",
	"text-decoration-line": "initial",
	"border-color": "--color-darkL1",
	"border-style": "solid",
	"hover-color": "--light",
	"hover-background": "--color-darkL1"
};
const overrides = {};

const ButtonContact = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Button {...rest}>
		Зв'яжіться з нами
		{children}
	</Button>;
};

Object.assign(ButtonContact, { ...Button,
	defaultProps,
	overrides
});
export default ButtonContact;